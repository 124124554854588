import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "app-image-landing",
    templateUrl: "./image-landing.component.html",
    styleUrls: ["./image-landing.component.scss"]
})
export class ImageLandingComponent implements OnInit {
    public imageSrc$!: Observable<SafeUrl | null>;

    constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.imageSrc$ = this.route.data.pipe(
            map((data) => {
                const { imageUrl } = data;
                if (imageUrl) {
                    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
                } else {
                    return null;
                }
            })
        );
    }
}
