import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class ConfigService {
    constructor(private http: HttpClient) {}

    public async getConfig<T>(path: string, fileName: string) {
        const data = await this.http
            .get<T>(`${environment.baseUrl}/config`, {
                params: {
                    path,
                    fileName
                }
            })
            .toPromise();
        return data;
    }
}
