import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-404",
    templateUrl: "./404.component.html",
    styleUrls: ["./404.component.scss"]
})
export class FallbackComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
