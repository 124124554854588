import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FallbackComponent } from "./components/404/404.component";
import { ImageLandingComponent } from "./components/image-landing/image-landing.component";
import { LandingComponent } from "./components/landing/landing.component";

const routes: Routes = [
    {
        path: "tire-wall",
        component: ImageLandingComponent,
        data: {
            imageUrl: "assets/img/screenshots/tire-wall.png"
        }
    },
    {
        path: "tire-protection",
        component: ImageLandingComponent,
        data: {
            imageUrl: "assets/img/screenshots/tire-protection.png"
        }
    },
    {
        path: "local-ownership",
        component: ImageLandingComponent,
        data: {
            imageUrl: "assets/img/screenshots/local.png"
        }
    },
    {
        path: "service-warranty",
        component: ImageLandingComponent,
        data: {
            imageUrl: "assets/img/screenshots/national.png"
        }
    },
    {
        path: "products/:productId",
        component: LandingComponent
    },
    {
        path: "**",
        component: FallbackComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
