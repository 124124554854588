import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    ITPBrandedProduct,
    ITPBrandsConfig,
    ITPProduct,
    ITPProductType,
    ITPProductTypesConfig
} from "../interfaces/tirepros.interface";
import { ConfigService } from "./config.service";

@Injectable({
    providedIn: "root"
})
export class ProductsService {
    private products: ITPProduct[] = [];
    private brandsConfig!: ITPBrandsConfig | null;
    private productTypesConfig!: ITPProductTypesConfig | null;

    constructor(private http: HttpClient, private configService: ConfigService) {}

    /**
     * Get products from config based on given product type and map brand data to product data.
     * @param {string} productTypeId
     * @returns {Promise<ITPBrandedProduct[]>}
     */
    public async getProducts(productTypeId: string): Promise<ITPBrandedProduct[]> {
        const products: ITPProduct[] = await this.getAllProducts();
        const brandsConfig = await this.getBrandsConfig();
        const productsByType: ITPProduct[] = products.filter((product) => product.typeIds.includes(productTypeId));
        if (brandsConfig) {
            return productsByType.map((product: ITPProduct) => ({ ...product, brand: brandsConfig[product.brandId] }));
        } else {
            return [];
        }
    }

    /**
     * Get product from config based on given prodcut type and product id and map brand data to product data.
     * @param {string} productId
     * @returns {Promise<ITPBrandedProduct>}
     */
    public async getProduct(productId: string): Promise<ITPBrandedProduct> {
        const products: ITPProduct[] = await this.getAllProducts();
        const brandsConfig = await this.getBrandsConfig();
        const product: ITPProduct | undefined = products.find((product: ITPProduct) => product.id === productId);

        if (product && brandsConfig) {
            return { ...product, brand: brandsConfig[product.brandId] };
        } else {
            throw new Error(`Unable to find product with id ${productId}`);
        }
    }

    /**
     * Get product type object from product type id.
     * @param productTypeId
     * @returns {Promise<ITPProductType>}
     */
    public async getProductType(productTypeId: string): Promise<ITPProductType> {
        const productTypesConfig = await this.getProductTypesConfig();
        if (productTypesConfig) {
            const productType: ITPProductType | undefined = productTypesConfig[productTypeId];
            if (productType) {
                return productType;
            } else {
                throw new Error(`Unable to find product type with id ${productTypeId}`);
            }
        } else {
            throw new Error("Inavlid product types config");
        }
    }

    public async getAllProducts(): Promise<ITPProduct[]> {
        if (this.products.length > 0) {
            return this.products;
        } else {
            // Get enabled product ids for this location from the api

            const products = await this.configService.getConfig<ITPProduct[]>("tire-pros", "tirewall-products.json");
            if (!products) {
                throw new Error("Unable to fetch products config");
            }
            // Remove any productIds that don't match any of the products
            // If productIds is empty, assume no configuration has been done and return all products
            this.products = products;
            return this.products;
        }
    }

    public async getBrandsConfig(): Promise<ITPBrandsConfig> {
        if (this.brandsConfig) {
            return this.brandsConfig;
        } else {
            const config = await this.configService.getConfig<ITPBrandsConfig>("tire-pros", "tirewall-brands.json");
            this.brandsConfig = config;
            if (!config) {
                throw new Error("Unable to fetch brands config");
            }
            return config;
        }
    }

    private async getProductTypesConfig(): Promise<ITPProductTypesConfig> {
        if (this.productTypesConfig) {
            return this.productTypesConfig;
        } else {
            const config = await this.configService.getConfig<ITPProductTypesConfig>(
                "tire-pros",
                "tirewall-productTypes.json"
            );
            this.productTypesConfig = config;
            if (!config) {
                throw new Error("Unable to fetch product types config");
            }
            return config;
        }
    }

    public async getAllProductTypes(): Promise<ITPProductType[]> {
        const config = await this.getProductTypesConfig();
        return Object.keys(config).map((key) => ({ id: key, name: config[key].name }));
    }
}
