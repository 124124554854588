import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ITPBrandedProduct } from "../../shared/interfaces/tirepros.interface";
import { ProductsService } from "../../shared/services/products.service";

@Component({
    selector: "app-landing",
    templateUrl: "./landing.component.html",
    styleUrls: ["./landing.component.scss"]
})
export class LandingComponent implements OnInit {
    public product$!: Observable<ITPBrandedProduct | null>;

    public showVideo: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private productsService: ProductsService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.getProduct();
    }

    public getProduct() {
        this.product$ = this.route.params.pipe(
            switchMap((params) => {
                if (params.productId) {
                    return this.productsService.getProduct(params.productId);
                } else {
                    return of(null);
                }
            }),
            map((product) => {
                if (product) {
                    product.safeDescription = this.sanitizer.bypassSecurityTrustHtml(product.description);
                    if (product.videoFileName) {
                        product.safeVideoSrc = this.sanitizer.bypassSecurityTrustUrl(
                            `${environment.assetsUrl}/tire-wall/videos/${product.videoFileName}`
                        );
                    }
                }
                return product;
            }),
            tap((product) => {
                if (!product) {
                    this.router.navigate(["/404"]);
                }
            })
        );
    }

    public toggleVideo() {
        this.showVideo = !this.showVideo;
    }
}
