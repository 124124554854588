<ng-container *ngIf="product$ | async as product">
    <div class="page-container">
        <div class="product semibold">
            <div class="product__header">{{ product.name }}</div>
            <div class="product__body">
                <div class="flex-container">
                    <div class="flex-container flex-container--left">
                        <div class="product__hero">
                            <img [src]="product.heroImageUrl" onerror="this.src='assets/img/tire-heroes/tire-placeholder.png'" alt="" />
                        </div>
                    </div>
                    <div class="flex-container flex-container--right">
                        <a *ngIf="product.videoFileName" (click)="toggleVideo()" class="product__video-button"
                            >Watch Video <img src="assets/img/arrow.png" alt=""
                        /></a>
                        <div *ngIf="product.brand.logoUrl" class="product__brand-logo">
                            <img [src]="product.brand.logoUrl" alt="" />
                        </div>
                    </div>
                </div>
                <div class="product__description">
                    <div id="innerHTML" [innerHTML]="product.safeDescription"></div>
                </div>
                <div class="flex-container flex-container--end">
                    <div class="product__warranty">
                        <div class="warranty__header">Drive with Confidence</div>
                        <ul>
                            <li *ngFor="let warranty of product.warranty">
                                {{ warranty }}
                            </li>
                        </ul>
                    </div>
                    <div class="triangle"></div>
                    <div class="product__info">
                        <div class="product__best-for">
                            <div class="info__header">Best For</div>
                            <ul>
                                <li *ngFor="let bestFor of product.bestFor">
                                    {{ bestFor }}
                                </li>
                            </ul>
                        </div>
                        <div class="product__conditions">
                            <div class="info__header">Conditions</div>
                            <ul>
                                <li *ngFor="let condition of product.conditions">
                                    {{ condition }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="video" *ngIf="showVideo">
        <span class="video__close" (click)="toggleVideo()">✖</span>
        <video [src]="product.safeVideoSrc" autoplay></video>
    </div>
</ng-container>
